// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import "@fortawesome/fontawesome-free/css/all.css";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("admin-lte");
window.jQuery = $;
window.$ = $;
require('jquery')
import "cocoon";
require('easy-autocomplete')
import "packs/client"
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbolinks:load', () => {
  togglePassword();
  var options = {

    url: function(phrase) {
      return "/clients/autocomplete.json?q=" + phrase;
    },
    getValue: "name",
    list: {
      onChooseEvent: function() {
        var selectedItemValue = $("#clients").getSelectedItemData().id;
        var createInvoice = $(".create_invoice")
        if ( createInvoice.length == 1 ) {
          $.ajax({
            type: 'GET',
            url: '/clients/'+selectedItemValue,
            dataType: 'json',
            success: function(response) {
              $('#invoice_client_attributes_first_name').val(response["first_name"]);
              $('#invoice_client_attributes_last_name').val(response["first_name"]);
              $('#invoice_client_attributes_email').val(response["email"])
              $('#invoice_client_attributes_tax_id').val(response["tax_id"]);
              $('#invoice_client_attributes_phone_number').val(response["phone_number"]);
              $('#invoice_client_attributes_address').val(response["address"]);
              $('#invoice_client_attributes_invoice_term').val(response["invoice_term"]);
              $('#invoice_client_attributes_id').val(selectedItemValue);
            }
          });
        }
        else {
          window.location = "/clients/"+selectedItemValue;
        }
      },
      onHideListEvent: function() {
        if ($(".create_invoice").length){
          var containerList = $('#clients').next('.easy-autocomplete-container').find('ul'); if ($(containerList).children('li').length <= 0) { $(containerList).html('<li>Nieko nerasta</li>').show(); }
        }else{
          var containerList = $('#clients').next('.easy-autocomplete-container').find('ul'); if ($(containerList).children('li').length <= 0) { $(containerList).html('<li class="new-client">Sukurti naują klientą +</li>').show(); }
        }
      }
    }
  };
  $("#clients").easyAutocomplete(options);
  $(document).on('click', '.new-client' , function(e) {
    $.ajax({
      method: 'GET',
      url: '/clients/new?invoice=true'
    })
  });

});

function togglePassword(){
  $("#show_hide_password a").on('click', function(event) {
    event.preventDefault();
    if($('#show_hide_password input').attr('type') == 'text'){
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass( "fa-eye-slash" );
      $('#show_hide_password i').removeClass( "fa-eye" );
      } else if($('#show_hide_password input').attr('type') == 'password') {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass( "fa-eye-slash" );
      $('#show_hide_password i').addClass( "fa-eye" );
    }
  });
}